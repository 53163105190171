// @ts-check

const isDevelopment = window.location.hostname === 'localhost';
const environment = isDevelopment ? 'development' : 'prod';

const domain = window.location.host.split('.').slice(-2).join('.');
const { protocol } = window.location;

const configs = {
  development: {
    basePublicUrl: 'http://localhost:3390',
    baseHealthUrl: 'http://localhost:3369',
    baseNotificationUrl: 'http://localhost:3351',
    baseFileUrl: 'http://localhost:3370',
  },
  prod: {
    basePublicUrl: `${protocol}//api.${domain}`,
    baseHealthUrl: `${protocol}//api.${domain}`,
    baseNotificationUrl: `${protocol}//api.${domain}`,
    baseFileUrl: `${protocol}//api.${domain}`,
  },
};

const config = configs[environment] || configs.development;

export default config;
